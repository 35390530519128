<template>
  <video-player
    ref="videoPlayer"
    class="video_container"
    :options="playerOptions"
    @play="onPlayerPlay($event)"
    @ready="onPlayerReady($event)"
  />
</template>

<script>
import _ from 'lodash';
import Hls from 'hls.js';

import VideoPlayer from '@/components/VideoPlayer.vue'
import Loader from './Loader';

export default {
  name: 'VideoStream',
  components: {
    Loader,
    VideoPlayer
  },

  props: {
    video: String,
    title: String,
    id: [String, Number],
    stopVideo: Boolean
  },
  data() {
    return {
      fullscreen: false,
      videoProgress: 0,
      onMouseMove: false,
      previewTime: '0',
      previewPosition: 0,
      pause: false,
      loading: true,
      hls: null,
      date: new Date().toLocaleString('ru-Ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      time: new Date().getTime().toLocaleString(),
      timeout: null,
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      }
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },
  mounted() {
    const src = this.$props.video;
    console.log('src', src)
    this.playVideo(src);
  },
  methods: {
    onPlayerPlay(player) {
    },
    onPlayerReady(player) {
      this.player.play();
    },
    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'application/x-mpegurl',
        src: source
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },
    switchPlayer() {
      this.playVideo(this.src);
    }
  }
};
</script>

<style scoped lang="scss">
$videoWidth: calc((100vh - 624px) * 1.76);
$videoHeight: calc(100vh - 629px);
.play-pause__button {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  margin-top: -44px;
  margin-left: -44px;
  cursor: pointer;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px -1px 13px rgba(255, 255, 255, 0.33);
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.2s;

  &:hover {
    opacity: 1 !important;
  }
}
::v-deep .loader{
  position: absolute;
}

.progress-bar__preview {
  pointer-events: none;
  user-select: none;
  //background: red;
  position: absolute;
  bottom: 24px;
  transform: translateX(-50%);
  /*width: 25%;
  height: 25%;
  max-width: 336px;
  max-height: 210px;
  min-width: 155px;
  min-height: 87px;*/
  opacity: 0;
  /* transition-delay: .2s;
   transition: .3s;*/

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .time {
    padding: 4px 8px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(#f6f9fe, 0.72);
    border-radius: 4px;
  }
}
::v-deep .video-js{
  height: 100%;
  width: 100%;
}
.video_container {
  background: #fef8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $videoWidth;
  height: $videoHeight;
  min-height: 349px;
  min-width: 621px;
  position: relative;
  overflow: hidden;

  progress {
    opacity: 0;
    transition: 0.3s;
    transition-delay: 0.2s;
    position: absolute;
    cursor: pointer;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 16px;
    border: none;
    background: #434343;

    &::-moz-progress-bar {
      color: rgba(#3d75e4, 0.72);
      background: rgba(#ffffff, 0.72);
    }

    &[value]::-webkit-progress-bar {
      background-color: rgba(#ffffff, 0.72);
    }

    &[value]::-webkit-progress-value {
      background-color: rgba(#3d75e4, 0.72);
    }
  }

  &.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: #04153e;
  }

  video {
    width: 100%;
    height: 100%;
  }

  ::v-deep .loading-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    .loading-ring__text {
      display: none;
    }
  }

  .controls {
    //transform: translateY(-100%);
    opacity: 0;
    transition: 0.3s;
    transition-delay: 0.2s;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%);
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    ::v-deep svg {
      path {
        fill: #ffffff;
      }
    }
  }

  &:hover {
    .controls {
      transform: translateY(0);
      opacity: 1;
    }

    progress {
      transform: translateY(0);
      opacity: 1;
    }

    .progress-bar__preview {
      opacity: 1;
    }

    .play-pause__button {
      opacity: 0.72;
    }
  }

  &.video_pause {
    .controls {
      transform: translateY(0);
      opacity: 1;
    }

    .progress-bar__preview {
      opacity: 1;
    }

    progress {
      transform: translateY(0);
      opacity: 1;
    }

    .play-pause__button {
      opacity: 0.72;
    }
  }
}
</style>
