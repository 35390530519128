import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getSave(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateGarbage', payload);

    return data;
  }

  async getDelete(payload) {
    const { data } = await this._axios.put('/ajax.php?action=operateGarbage', payload);

    return data;
  }

  async getLoadId(id) {
    const { data } = await this._axios.get(`/ajax.php?action=getGarbage&id=${id}`);
    return data;
  }

  async getList(payload) {
    const noCache = Math.floor(Math.random(1) * 1000);
    // const { data } = await this._axios.post(`ajax.php?action=getPlaces`, payload);
    // const { data } = await this._axios.post(`ajax.php?action=getPlaces&r=${noCache}`, payload);
    const { data } = await this._axios.get('/ajax.php?action=getGarbage', payload);
    return data;
  }

  async getGarbage(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getGarbage', payload);
    return data;
  }

  async getCoors(payload) {
    const { data } = await this._axios.get('/ajax.php?action=getAll&v=2&wPhotos=1&json={"filterTypes":"2"}');
    return data;
  }

  async getCovid(payload, id) {
    const { data } = await this._axios.post(`/cityProperty/rest/service/covid/${id}/${payload}`);
    return data;
  }

  async getCovidCancel(payload, id) {
    const { data } = await this._axios.delete(`/cityProperty/rest/service/covid/${id}`);
    return data;
  }

  async getPoly(payload) {
    const { data } = await this._axios.post('/cityProperty/rest//service/inPoly', payload);
    return data;
  }

  async getVideo() {
    const res = await this._axios.get('/trassir/rest/channel');
    // console.log(res);
    return res.data;
  }

  async uploadData(url, data) {
    const res = await this._axios.post(url, data);

    // console.log(res);
  }

  async getSensorCam(url, params) {
    return await this._axios.get(url, {
      params
    });
  }

  async getScreenshotSensorCam(url, params) {
    return await this._axios.get(url, {
      params
    });
  }
  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }
}
