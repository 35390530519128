import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import LoadScript from 'vue-plugin-load-script';
import App from './App.vue';
import router from './router';
import store from './store';
import rir from './plugins/RirLib';
import YmapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd';

console.log('window', window)
Vue.prototype.$storage = window?.__CONFIG__?.storage || null;
Vue.prototype.$cityId = window?.__CONFIG__?.cityId || '16';
Vue.prototype.$cityName = window?.__CONFIG__?.cityName || '';
Vue.prototype.$cityCenter = Array.isArray(window?.__CONFIG__?.cityCenter?.latLng)
  ? [...window?.__CONFIG__?.cityCenter?.latLng] // .reverse()
  : process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);

const mapSettings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};
window._axios.interceptors.response.use(response => { // For status code with 2xx
  if (response?.error) {
    rir.framework.notification.send({
      title: response?.error || 'Ошибка!',
      seconds: 5,
      hiddenClose: false,
      iconOptions: {
        icon: 'warning',
        fill: 'fargo'
      }
    });
  }
  return response;
}, error => { // Status code outside the range of 2xx
  // handle error cases
  console.error('error', error);
  return Promise.reject(error);
});
if (process.env.NODE_ENV === 'development') {
  window.__CONFIG__ = {
    storage: process.env.VUE_APP_PUBLIC_FOLDER
  };
}
Vue.prototype.$tko = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_TKO_ICON
};
Vue.prototype.$tkoCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_TKO_ICON_CAM
};
Vue.prototype.$wasteBin = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_BIN_ICON
};
Vue.prototype.$wasteBinCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_BIN_ICON_CAM
};
Vue.prototype.$wasteCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_ICON
};
Vue.prototype.$wasteCamCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_ICON_CAM
};
Vue.prototype.$separate = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_SEPARATE
};
Vue.prototype.$separateCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_WASTE_SEPARATE_CAM
};
Vue.prototype.$problem = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_PROBLEM
};
Vue.prototype.$markerIcon = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON
};
Vue.prototype.$markerIconCam = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON_CAM
};
Vue.prototype.$container = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_CONTAINER
};
Vue.prototype.$sensor = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_SENSOR
};
Vue.prototype.$camera = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_CAMERA
};
Vue.prototype.$appeal = {
  imageSize: [55, 68],
  layout: 'default#image',
  imageHref: "data:image/svg+xml;charset=UTF-8,%3csvg width='56' height='68' viewBox='0 0 56 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url(%23filter0_d_58_194282)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 24C40 17.3726 34.6274 12 28 12C21.3726 12 16 17.3726 16 24C16 30.6274 21.3726 36 28 36C28.9385 36 28.1059 39.0311 27.2721 42.0663C26.4391 45.0986 25.5 48 26.5352 48C28.7597 48 40 34.8279 40 24Z' fill='%23113079'/%3e%3ccircle cx='28' cy='24' r='6' fill='white'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='filter0_d_58_194282' x='0' y='0' width='56' height='68' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='4'/%3e%3cfeGaussianBlur stdDeviation='8'/%3e%3cfeColorMatrix type='matrix' values='0 0 0 0 0.0156863 0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0.16 0'/%3e%3cfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_58_194282'/%3e%3cfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_58_194282' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e"
};
Vue.prototype.$appealGrey = {
  imageSize: [55, 68],
  layout: 'default#image',
  imageHref: "data:image/svg+xml;charset=UTF-8,%3csvg width='56' height='68' viewBox='0 0 56 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url(%23filter0_d_58_194282)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 24C40 17.3726 34.6274 12 28 12C21.3726 12 16 17.3726 16 24C16 30.6274 21.3726 36 28 36C28.9385 36 28.1059 39.0311 27.2721 42.0663C26.4391 45.0986 25.5 48 26.5352 48C28.7597 48 40 34.8279 40 24Z' fill='%236F7A90'/%3e%3ccircle cx='28' cy='24' r='6' fill='white'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='filter0_d_58_194282' x='0' y='0' width='56' height='68' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='4'/%3e%3cfeGaussianBlur stdDeviation='8'/%3e%3cfeColorMatrix type='matrix' values='0 0 0 0 0.0156863 0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0.16 0'/%3e%3cfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_58_194282'/%3e%3cfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_58_194282' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e"
};

Vue.use(YmapPlugin, mapSettings);

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions
          // for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});
export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
