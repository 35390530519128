<template>
  <div class="objects__map mt-6">
    <rir-map
      ref="myChild"
      :array-for-heat-map="heatmapData"
      :heatmap="heatmap"
    >
      <ymap-marker
        v-for="marker in filteredObjects"
        :key="marker.id"
        :coords="[`${marker.lat}`, `${marker.lng}`]"
        :marker-id="marker.id"
        :options="{ hideIconOnBalloonOpen: false }"
        :icon="marker.camera_object.length > 0 ?
          getMapIcons(marker.type).markerIconCam:
          getMapIcons(marker.type).markerIcon"
        :balloon="{header: {id: marker.id, name: marker.address}}"
        :cluster-name="marker.camera_object.length > 0 ? 'covid' : 'cluster'"
      >
        <balloon-card
          slot="balloon"
          :id="marker.id"
          :item="marker"
          :type-id="marker.type"
          :type="marker.typeName"
          :address="marker.address"
          :data-id="marker.id"
        />
      </ymap-marker>
      <ymap-marker
        v-if="appealsGarbage"
        v-for="marker in appealsGarbageData"
        :key="marker.id"
        :coords="[`${marker.lat}`, `${marker.lng}`]"
        :marker-id="marker.id"
        :options="{ hideIconOnBalloonOpen: false }"
        :icon="getAppealsMapIcon(marker.hidden)"
        :balloon="{header: {id: marker.id, name: marker.address}}"
      >
        <balloon-appeals
          slot="balloon"
          :marker="marker"
          :data-id="marker.id"
        />
      </ymap-marker>
    </rir-map>
  </div>
</template>

<script>
import RirMap from '@/components/RirMap.vue';
import BalloonCard from '@/components/BalloonCard.vue';
import PlaceApi from '../api/PlaceApi';
import BalloonAppeals from './BalloonAppeals';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';

export default {
  name: 'Map',
  components: {
    RirMap,
    BalloonCard,
    BalloonAppeals
  },

  props: {
    appealsGarbageData: {
      type: Array,
      default: () => []
    },
    appealsGarbage: {
      type: Boolean,
      default: false
    },
    heatmap: {
      type: Boolean,
      default: false
    },
    check: {
      type: Boolean,
      default: false
    },
    isCheckMusor: {
      type: Boolean,
      default: false
    },
    checkHouse: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    dateFrom: {
      type: String,
      default: null
    },
    dateTo: {
      type: String,
      default: null
    },
    isType: {
      type: String,
      default: ''
    },
    isUnderVision: {
      type: Number,
      default: 0
    }
  },
  emits: ['count'],
  data() {
    return {
      isCollapsed: true,
      mapZoom: 13,
      myMap: null,
      count: 0,
      heatmapData: []
    };
  },
  computed: {
    allObjects() {
      const { objects } = this.$store.state;
      return objects;
    },
    filteredAppeal() {
      const objects = [];

      if (this.$store.state.coors != null) {
        if (this.$store.state.coors.all) {
          // console.log('sdfsdfsdfsdf')
          const objectsAll = this.$store.state.coors.all;
          const objectsAllItem = objectsAll.map(el => ({
            id: el.id,
            address: el.address,
            title: el.title,
            avatara: el.avatara,
            lat: el.lat,
            lng: el.lng
          }));
          objects.push(...objectsAllItem);
          return objects;
        }
      }
    },
    filteredObjects() {
      let objects = [];
      if (this.$store.state.objects != null) {
        const objectsAll = this.$store.state.objects.all.filter(el => el.lat && el.lng && el.hidden != '1');
        const objectsAllItem = objectsAll.map(el => ({
          id: el.id,
          address: el.address,
          type: parseInt(el.type),
          FillingPercentage: (el.FillingPercentage ? el.FillingPercentage : ' - '),
          updated: (el.updated ? el.updated : ' - '),
          camera_guid: (!!el.camera_guid),
          typeName: String(this.filteredContainer[parseInt(el.type)]),
          lat: el.lat,
          lng: el.lng,
          extraData: {
            ...el.extraData,
            clock: el.extraData?.clock ? el.extraData?.clock : '00:00 - 00:00',
            days: el.extraData?.days ? el.extraData?.days : ''
          },
          schedule: el.schedule,
          camera_object: el.camera_object ? el.camera_object : []
        }));
        objects.push(...objectsAllItem);
        if (this.isUnderVision === 1) {
          objects = objects.filter(object => !!object.camera_object.length < 1);
        }
        if (this.isUnderVision === 2) {
          objects = objects.filter(object => !!object.camera_object.length > 0);
        }
        /* if (this.check) {
          return objects.filter(
            el => ((this.isSearch != '' ? el.address.toLowerCase().indexOf(this.isSearch) !== -1 : true)
                || (this.isSearch != '' ? el.typeName.toLowerCase().indexOf(this.isSearch) !== -1 : true))
              && ((this.isType != 0 ? el.type == this.isType : true))
              && (el.camera_object.length > 0 ? true : false)
          );
        } */
        objects = objects.filter(
          el => ((this.isSearch != '' ? el.address.toLowerCase().indexOf(this.isSearch) !== -1 : true)
              || (this.isSearch != '' ? el.typeName.toLowerCase().indexOf(this.isSearch) !== -1 : true))
            && ((this.isType != 0 ? el.type == this.isType : true))
        );
        this.$emit('count', objects.length);
        return !this.heatmap ? objects : [];
      }
    },
    filteredContainer() {
      const objects = [
        'Все',
        'Контейнер',
        'Площадка ТКО',
        'Бункер-накопитель',
        'Мусорокамера',
        'Раздельный сбор'
      ];

      // console.log(objects);
      return objects;
    }

  },
  async mounted() {
    const v = this;
    const objects = [];
    if (this.$store.state.objects != null) {
      const objectsAll = this.$store.state.objects.all.filter(el => el.lat && el.lng)
        .map(el => ({
          id: el.id,
          address: String(el.address),
          typeName: String(this.filteredContainer[parseInt(el.type)]),
          lat: el.lat,
          lng: el.lng
        })).filter(
          el => (this.isSearch != '' ? el.address.toLowerCase().indexOf(this.isSearch) !== -1 : true)
            || (this.isSearch != '' ? el.typeName.toLowerCase().indexOf(this.isSearch) !== -1 : true)
        );
      const objectsAllItem = objectsAll.map(el => {
        if (el.lat && el.lng) {
          objects.push([Number(el.lat), Number(el.lng)]);
        }
      });
    }

    this.heatmapFunc();
    this.loadMap();
  },
  methods: {
    getMapIcons(type) {
      const icons = {
        1: {
          markerIconCam: this.$markerIconCam,
          markerIcon: this.$markerIcon
        },
        2: {
          markerIconCam: this.$tkoCam,
          markerIcon: this.$tko
        },
        3: {
          markerIconCam: this.$wasteBinCam,
          markerIcon: this.$wasteBin
        },
        4: {
          markerIconCam: this.$wasteCamCam,
          markerIcon: this.$wasteCam
        },
        5: {
          markerIconCam: this.$separateCam,
          markerIcon: this.$separate
        }
      };
      return icons[type] || {
        markerIcon: this.$markerIcon
      };
    },
    heatmapFunc() {
      this.heatmapData = this.appealsGarbageData.map(el => {
        if (el.lat && el.lng) {
          return [Number(el.lat), Number(el.lng)];
        }
      }).filter(
        el => (el != undefined)
      );
      if (!this.heatmap) {
        this.heatmapData = [];
      }
    },
    async loadMap() {
      const v = this;
      const settings = { lang: 'ru_RU' };
      await loadYmap(settings);
      const yandexMapScript = document.createElement('SCRIPT');
      const link = 'https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js';
      yandexMapScript.setAttribute('src', link);
      document.head.appendChild(yandexMapScript);
      yandexMapScript.onload = () => {
        v.map();
      };
    },
    map() {
      const v = this;

      window.ymaps.ready(() => {
        if (v.myMap) {
          v.myMap.destroy();
        }
        v.myMap = new window.ymaps.Map('YMapsID', {
          center: v.$cityCenter,
          zoom: v.mapZoom,
          controls: []

        }, {
          searchControlProvider: 'yandex#search'
        });
        const getPointOptions = function () {
          return {
            preset: 'islands#violetIcon'
          };
        };
        window.ymaps.modules.require(['Heatmap'], Heatmap => {
          const heatmap = new Heatmap(v.heatmapData);
          heatmap.options.set('gradient', {
            0.1: 'rgba(128, 255, 0, 0.7)',
            0.2: 'rgba(162, 36, 25, 0.7)',
            0.2: 'rgba(255, 255, 0, 0.8)'
          });
          heatmap.options.set('radius', 15);
          heatmap.setMap(v.myMap);
        });
      });
    },
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
    },
    getAppealsMapIcon(hidden) {
      if (+hidden === -1 || +hidden === 1 || +hidden === 2) {
        return this.$appealGrey;
      }
      return this.$appeal;
    },
    onMapInit(e) {
      this.mapInstance = e;
    },
    onZoom(val) {
      const v = this;
      const newZoom = v.mapZoom + val;
      v.mapZoom = newZoom;
      v.myMap.setZoom(v.mapZoom);
    }
  }
};
</script>

<style lang="scss" scoped>
.zoom {
  position: absolute;
  z-index: 10;
  margin-left: calc(100% - 56px);
  margin-top: 16px;
}
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      width: 40px;
      height: 40px;
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}

::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-copyrights-pane {
  display: none;
}

::v-deep .ymaps-2-1-79-map-copyrights-promo {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon {
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}

.ymaps-2-1-79-default-cluster > * {
  margin-top: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

ymaps-2-1-79-default-cluster {
  margin-top: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}
</style>
