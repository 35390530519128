<template>
  <div>
    <router-link
      :to="{
        name: 'edit',
        params: { id: `${id}` }
      }"
    >
      <div class="r-map-balloon">
        <div class="r-map-balloon__info">
          <p class="roquefort">
            {{ type }}
          </p>
          <p class="mt-3 сaprino r-map-balloon__address">
            {{ address }}
          </p>
          <div
            class="obj-card__counters flex obj-card__address mozzarella style-marin-house mt-3"
            v-if="loadCont(item.extraData, typeId) "
          >
            <div
              class=" mr-2 img"
              v-show="paper"
            >
              <img src="../../public/icon/knob.svg">
            </div>
            <div
              class=" mr-2  img"
              v-show="glass"
            >
              <img src="../../public/icon/glass.svg">
            </div>
            <div
              class="mr-2 img"
              v-show="metal"
            >
              <img src="../../public/icon/paper.svg">
            </div>
            <div
              class="mr-2 img"
              v-show="plastic"
            >
              <img src="../../public/icon/plastic.svg">
            </div>
            <div
              class="mr-2 img"
              v-show="other"
            >
              <img src="../../public/icon/different.svg">
            </div>
          </div>
          <div
            class="obj-card__counters flex obj-card__address mozzarella style-marin-house mt-1"
            v-if="typeId == 5"
          >
            Информация о пунктах приема собрана в рамках проекта «Чистый город начинается с тебя»
          </div>
          <div class="mt-3">
            <div class="flex align-cenetr">
              <div class="flex mr-5">
                <r-icon
                  class="mr-2"
                  icon="clock"
                  fill="rocky"
                  size="16"
                />
                <span
                  class="briscola clock mb-2"
                  v-if="item.extraData != null"
                >
                  {{ (item.schedule ? item.schedule : (item.extraData ? item.extraData.days + ' ' + item.extraData.clock : '')) }}
                </span>
                <span
                  class="briscola clock mb-2"
                  v-else
                >
                  -
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    type: {
      type: String
    },
    address: {
      type: String
    },
    id: {
      type: String
    },
    item: {
      type: Object
    },
    typeId: {
      type: Number
    }
  },
  data() {
    return {
      paper: false,
      glass: false,
      metal: false,
      plastic: false,
      other: false
    };
  },
  methods: {
    loadCont(garbage, type) {
      if (garbage != null && type == 5) {
        // console.log(garbage)
        const garbageItem = garbage.garbageType.split(',');
        garbageItem.forEach(element => {
          if (element == 'Бумага') {
            this.paper = true;
          }
          if (element == 'Стекло') {
            this.glass = true;
          }
          if (element == 'Металл') {
            this.metal = true;
          }
          if (element == 'PET пластик') {
            this.plastic = true;
          }
          if (element == 'Другое') {
            this.other = true;
          }
        });
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 200px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
<style>
.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.leaflet-container a {
  color: unset !important;
}
.leaflet-popup-tip-container {
  display: none;
}
.leaflet-popup-content {
  width: unset !important;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}
.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup-content p {
  margin: 4px 0 !important;
}
.roquefort {
  color: #0e1420;
}

.clock {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}
</style>
